import React, { useState, useEffect } from 'react';
import contentfulConfig from '../../config/contentfulConfig';
import { createClient } from 'contentful';
import Button from '@mui/material/Button';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Loading from "../../components/Loading";
const client = createClient({
  space: contentfulConfig.space,
  accessToken: contentfulConfig.accessToken,
  
});
function ProjectData(props) {
    // const projectId = props.projectId;

  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const currentURL = window.location.href;

        // Use regular expression to extract the value
        const regex = /projects\/([^/]+)/;
        const match = currentURL.match(regex);

        // Check if a match was found and get the value
        let projectId = null;
        if (match) {
        projectId = match[1];
        } else {
        console.log("Value not found");
        }
    // Fetch data from Contentful
    client
      .getEntry(
         projectId
        

      )
      .then((response) => {
        setFields(response.fields);
        setLoading(false); // Set loading to false when the data is available
        console.log(response)

      })
      .catch(console.error);
  }, []);
  if (loading) {
    return <Loading />

  }
  let projectImages = [fields.project_images][0];
  let projectKeys = [fields.projectKeys][0];
  return (
    <>
      <div className='container-full-width'>
        <div className='project-detail-main-wrapper'>
          {/* <PageHeader title={fields.title} description={fields.subTitle} /> */}
          <div className='project-text-wrapper'>
          <h1 className='project-title'>{fields.title} </h1>
          <h2 className='project-sub-title'>{fields.subTitle} </h2>

          
          {/* <div className='my-role mt-5 '><h2 className='my-role me-3'>My Role </h2> <span> {fields.myRole} </span></div> */}


          <div className='project-description my-4'>
            <h2 className=''>Project Description</h2>
            {documentToReactComponents(fields.description)}
          </div>


          <div className='project-keys-wrapper mt-4'>
            <h2 className=''> Project Keys</h2>
            <div className='project-keys-wrapper'>
                {Array.isArray(projectKeys) && projectKeys.length > 0 ? (
                projectKeys.map((key, index) => (
                  <p key={index} className='project-key' >{key}</p>
                ))
                ) : (
                <p>No project keys available.</p>
                )}
            </div>
        
            
          </div>

          </div>
          <div className='project-images-wrapper'>
                {Array.isArray(projectImages) && projectImages.length > 0 ? (
                projectImages.map((asset, index) => (
                    <img key={index} className='featured-image' src={asset.fields.file.url} />
                ))
                ) : (
                <p>No project images available.</p>
                )}
          </div>

        </div>
       
      

      </div>
      
      
    </>
  );
  
}

export default ProjectData;
